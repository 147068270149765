import React, { useContext } from 'react';
import { Section } from '../components/section/section';
import { Title } from '../components/title/title';
import { Link } from '../components/link/link';
import { Content } from '../components/content/content';
import { Overlay } from '../components/overlay/overlay';
import { LayoutContext } from '../components/layout/layout';
import { Meta } from '../components/meta/meta';
import { Container } from '../components/container/container';
import { ServiceCards } from '../components/service-cards/service-cards';
import { Subtitle } from '../components/subtitle/subtitle';

const NotFound = () => {
  const { navOpen } = useContext(LayoutContext);

  return (
    <>
      <Meta title="Page not Found" />
      <Section layout="fullscreen" maxh={['none', 600]} css={{ backgroundColor: '#000' }}>
        <Content h={1} css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Title mt="128px" color="#fff" hide={[navOpen, navOpen, 0]}>
            Page Not Found
          </Title>
        </Content>
        <Content
          hide={[1, 0]}
          css={{
            position: 'absolute',
            bottom: 0,
            height: '80px',
            color: '#fff',
            backgroundColor: 'rgba(0, 133, 255, .2)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Link
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '2px solid #ffffff80',
              borderRight: '1px solid #ffffff80',
            }}
          >
            Chesterfield Location | Get Directions
          </Link>
          <Link
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '1px solid #ffffff80',
              borderRight: '2px solid #ffffff80',
            }}
          >
            Pontiac Location | Get Directions
          </Link>
        </Content>
        <Overlay open={navOpen} />
      </Section>
      <Section>
        <Container maxw={960}>
          <Subtitle>Our Services</Subtitle>
        </Container>
        <ServiceCards />
      </Section>
    </>
  );
};

export default NotFound;
